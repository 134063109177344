// @flow

import React from 'react';
import Square from './square.jsx'
import NumPad from './num_pad'

type State = {
    userModeMulti: bool,
}

const COLORS = ["green", "lavender", "sky-blue", "brown", "tan", "pink", "purple", "sea-blue", "red", "yellow"];
const FULL_OPACITY = 'fullOpacity';
const PART_OPACITY = 'highlightOpacity';


type Props = {
    boardState: ?any,
    userList: Array<any>,
    userID: string,
    currentCell: ?Array<number>,
    onUpdateCurrentCellValue: (num: number) => void,
    onUpdateCurrentCell: (coords: Array<number>) => void,
    onToggleCurrentCellMode: () => void,
    addCurrentCellStateToUndoList: () => void,
    addCurrentCellStateToRedoList: () => void,
    onUndo: () => void,
    onRedo: () => void,
    onUpdateCurrentCellMultiValue: (?number) => void,
}

class Board extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { userModeMulti: false }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
    }

    setCell(num: number) {
        this.props.onUpdateCurrentCellValue(num);
    }

    clearSquare() {
        this.setCell(0);
        this.props.onUpdateCurrentCellMultiValue(null);
    }

    getSquareOutlineColor(row: number, col: number): ?string {
        const currentCell = this.props.currentCell;
        const cell = this.props.boardState?.rows[row].cells[col];

        if (currentCell != null && currentCell[0] === row && currentCell[1] === col) {
            const color_idx = this.props.userID.charCodeAt(0) % COLORS.length;
            return COLORS[color_idx];
        } else if (currentCell != null) {
            const currentCellValue = this.props.boardState?.rows[currentCell[0]].cells[currentCell[1]].single_value;
            if (cell != null && cell.single_value === currentCellValue && cell.single_value !== 0) {
                console.log(cell.single_value);
                const color_idx = this.props.userID.charCodeAt(0) % COLORS.length;
                return COLORS[color_idx];
            }
        }
        const users_on_square = this.props.userList?.filter(
            (user) => {
                return user.user_id !== this.props.userID &&
                    user.location[0] === row &&
                    user.location[1] === col

            }
        );
        if (users_on_square?.length > 0) {
            const userID = users_on_square[0].user_id;
            const color_idx = userID.charCodeAt(0) % COLORS.length;
            return COLORS[color_idx]
        }
        return null

    }

    getSquareOpacity(row: number, col: number): string {
        const currentCell = this.props.currentCell;
        const cell = this.props.boardState?.rows[row].cells[col];
        if (currentCell != null && cell != null) {
            if (currentCell[0] === row && currentCell[1] === col) {
                return FULL_OPACITY
            }
            const currentCellValue = this.props.boardState?.rows[currentCell[0]].cells[currentCell[1]].single_value;
            return currentCellValue === cell.single_value && cell.single_value !== 0 ? PART_OPACITY : FULL_OPACITY;
        }
        return FULL_OPACITY;
    }

    toggleUserMode(): void {
        this.setState((prevState: State) => {
            return {userModeMulti: !prevState.userModeMulti }
        });
    }

    render(): React$Element<"div"> {
        const currentCellCoords = this.props.currentCell;
        const currentCell = currentCellCoords != null ? this.props.boardState?.rows[currentCellCoords[0]].cells[currentCellCoords[1]] : null;
        return <div className={"boardAndNumPadContainer"}>
            <table className="sudoku">
                <tbody>
                    {this.props.boardState?.rows.map((row, idx) => {
                        return (
                            <tr key={idx}>
                                {row.cells.map((cell, cell_idx) => {

                                    return (
                                        <Square
                                            key={cell_idx}
                                            row={idx}
                                            col={cell_idx}
                                            opacityClass={this.getSquareOpacity(idx, cell_idx)}
                                            onClick={this.props.onUpdateCurrentCell}
                                            mutable={cell.mutable}
                                            mode={cell.mode}
                                            multiValue={cell.multi_value}
                                            outlineColor={this.getSquareOutlineColor(idx, cell_idx)}
                                            selected={this.props.currentCell != null && this.props.currentCell[0] === idx && this.props.currentCell[1] === cell_idx}
                                        >
                                            {cell.single_value === 0 ? " " : cell.single_value}
                                        </Square>
                                    )
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <NumPad
                addCurrentCellStateToUndoList={this.props.addCurrentCellStateToUndoList}
                addCurrentCellStateToRedoList={this.props.addCurrentCellStateToRedoList}
                onClearCell={this.clearSquare.bind(this)}
                onSetCell={this.setCell.bind(this)}
                onToggleCurrentCellMode={this.props.onToggleCurrentCellMode}
                currentCell={currentCell}
                onUpdateCurrentCellMultiValue={this.props.onUpdateCurrentCellMultiValue}
                onToggleUserMode={this.toggleUserMode.bind(this)}
                userModeMulti={this.state.userModeMulti}
                onUndo={this.props.onUndo}
                onRedo={this.props.onRedo}
            />
        </div>;
        // return <div>{this.state.hi}{JSON.stringify(this.props.boardState)}</div>;
    }
}

export default Board