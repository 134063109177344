// @flow

import * as React from 'react';
import logoIcon from '../logocolor_roundcorners.svg';
import "./top_bar.css";

type Props = {
    children?: React.Node,
    time: number,
    complete: bool,
    setSidebarOpen: (bool) => void,
    // propOne: bool,
}

type State = {
    isSidebarOpen: bool,
    // stateOne: bool,
}

class TopBar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        // initialize state:
        this.state = {isSidebarOpen: true};
        // do something
    }

    componentDidMount() {
        // do something 
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // do something
    }

    componentWillUnmount() {
        // do something
    }

    getDisplayTimeString(): string {
        const displayHHMMSSTimeString = new Date(this.props.time * 1000).toISOString().substr(11, 8);
        if (this.props.time < 60 * 60) {
            return displayHHMMSSTimeString.substr(3, 5);
        }
        return displayHHMMSSTimeString;
    }

    render(): React$Element<"div"> {
        return <div className="topbar-grid-container">
            <div className="topbar-logo">
                <div className={"topbar-logo-icon"}>
                    <a href="."><img src={logoIcon} className={"topbar-logo-icon"} /></a>
                </div>
                <div className="topbar-logo-text"><a className={"topbar-logo-text"} href=".">sudoku<span className={"logo-with"}>with.</span>us</a></div>
            </div>
            <div className="topbar-time">({this.getDisplayTimeString()}) {this.props.complete ? "Complete!" : ""}</div>
            {/* <div onClick={()=>{this.props.setSidebarOpen(true)}}>Menu</div> */}
        </div>
    }

}

export default TopBar