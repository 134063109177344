// @flow

import * as React from 'react';
import './num_pad.css';
//$FlowIgnore
import {ReactComponent as Eraser} from '../eraser.svg';
//$FlowIgnore
import {ReactComponent as Pencil} from '../pencil.svg';
//$FlowIgnore
import {ReactComponent as Undo} from '../undo.svg';
//$FlowIgnore
import {ReactComponent as Redo} from '../redo.svg';

type Props = {
    children?: React.Node,
    addCurrentCellStateToUndoList: () => void,
    addCurrentCellStateToRedoList: () => void,
    onClearCell: () => void,
    onSetCell: (number) => void,
    onUpdateCurrentCellMultiValue: (?number) => void,
    onToggleCurrentCellMode: () => void,
    currentCell: ?any,
    userModeMulti: bool,
    onToggleUserMode: () => void,
    onUndo: () => void,
    onRedo: () => void,
}

type State = {
    // stateOne: bool,
}

class NumPad extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        // initialize state:
        this.state = {};
        // do something
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown.bind(this));
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // do something
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown.bind(this));
    }

    handleKeyDown(event: KeyboardEvent) {
        console.log(event.key);
        if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'z') {
            this.props.onRedo();
        } else if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
            this.props.onUndo();
        } else if (event.shiftKey && !(event.ctrlKey || event.metaKey)) {
            console.log("toggling mode");
            this.props.onToggleUserMode();
        }

        switch (event.key) {
            case "1":
                this.onNumPressed(1);
                break;
            case "2":
                this.onNumPressed(2);
                break;
            case "3":
                this.onNumPressed(3);
                break;
            case "4":
                this.onNumPressed(4);
                break;
            case "5":
                this.onNumPressed(5);
                break;
            case "6":
                this.onNumPressed(6);
                break;
            case "7":
                this.onNumPressed(7);
                break;
            case "8":
                this.onNumPressed(8);
                break;
            case "9":
                this.onNumPressed(9);
                break;
            case "Backspace":
                this.onDeletePressed();
                break;
            default:
                break;
        }

    }

    updateMultiValues(num: number) {
        const currentCell = this.props.currentCell;
        if (currentCell != null) {
            this.props.addCurrentCellStateToUndoList();
            if (currentCell.mode === "multi") {
                this.props.onUpdateCurrentCellMultiValue(num);
            } else {
                this.props.onToggleCurrentCellMode();
                this.props.onSetCell(0);
                this.props.onUpdateCurrentCellMultiValue(num);
            }
            this.props.addCurrentCellStateToRedoList();
        }
    }

    updateSingleValues(num: number) {
        const currentCell = this.props.currentCell;
        if (currentCell != null) {
            if (currentCell.mode === "single" && currentCell.single_value !== num) {
                this.props.addCurrentCellStateToUndoList();
                this.props.onSetCell(num);
                this.props.addCurrentCellStateToRedoList();
            } else if (currentCell.mode === "multi") {
                this.props.addCurrentCellStateToUndoList();
                this.props.onToggleCurrentCellMode();
                this.props.onUpdateCurrentCellMultiValue(null);
                this.props.onSetCell(num);
                this.props.addCurrentCellStateToRedoList();
            }
        }
    }

    isNumPressed(num: number): bool {
        const currentCell = this.props.currentCell;
        if (currentCell != null && currentCell.mutable && currentCell.mode === (this.props.userModeMulti ? 'multi' : 'single')) {
            if (this.props.userModeMulti) {
                return currentCell.multi_value.includes(num);
            } else {
                return currentCell.single_value === num;
            }
        }
        return false;
    }

    getNumPressedClass(num: number): string {
        return this.isNumPressed(num) ? "button-pressed" : "";
    }

    onNumPressed(num: number): void {
        if (this.props.currentCell?.mutable) {
            const multiPressed = this.props.userModeMulti;
            multiPressed ? this.updateMultiValues(num) : this.updateSingleValues(num);
        }
    }

    currentCellHasValue(): boolean {
        return this.props.currentCell?.single_value !== 0 || this.props.currentCell?.multi_value.length !== 0;
    }

    onDeletePressed() {
        if (this.props.currentCell?.mutable && this.currentCellHasValue()) {
            this.props.addCurrentCellStateToUndoList();
            this.props.onClearCell();
            this.props.addCurrentCellStateToRedoList();
        }
    }

    render(): React$Element<"div"> {
        const multiPressed = this.props.userModeMulti;
        const deleteButton = <div className="num-pad-button num-pad-delete" onPointerDown={(e) => { e.preventDefault(); this.onDeletePressed()}}><Eraser className={"eraser"}/></div>;
        const toggleModeButton = <div className={"num-pad-button num-pad-multi " + (multiPressed ? "button-pressed" : "")} onPointerDown={(e) => { e.preventDefault(); this.props.onToggleUserMode() }}><Pencil className={"pencil"}/></div>;
        const undoButton = <div className="num-pad-button num-pad-undo" onPointerDown={(e) => { e.preventDefault(); this.props.onUndo()}}><Undo className={"undo"}/></div>;
        const redoButton = <div className="num-pad-button num-pad-redo" onPointerDown={(e) => { e.preventDefault(); this.props.onRedo()}}><Redo className={"redo"}/></div>;
        return <div className="num-pad-grid-container">
            <div className={"num-pad-button num-pad-one " + this.getNumPressedClass(1)} onPointerDown={(e) => { e.preventDefault(); this.onNumPressed(1) }}>1</div>
            <div className={"num-pad-button num-pad-two " + this.getNumPressedClass(2)} onPointerDown={(e) => { e.preventDefault(); this.onNumPressed(2) }}>2</div>
            <div className={"num-pad-button num-pad-three " + this.getNumPressedClass(3)} onPointerDown={(e) => { e.preventDefault(); this.onNumPressed(3) }}>3</div>
            <div className={"num-pad-button num-pad-four " + this.getNumPressedClass(4)} onPointerDown={(e) => { e.preventDefault(); this.onNumPressed(4) }}>4</div>
            <div className={"num-pad-button num-pad-five " + this.getNumPressedClass(5)} onPointerDown={(e) => { e.preventDefault(); this.onNumPressed(5) }}>5</div>
            <div className={"num-pad-button num-pad-six " + this.getNumPressedClass(6)} onPointerDown={(e) => { e.preventDefault(); this.onNumPressed(6) }}>6</div>
            <div className={"num-pad-button num-pad-seven " + this.getNumPressedClass(7)} onPointerDown={(e) => { e.preventDefault(); this.onNumPressed(7) }}>7</div>
            <div className={"num-pad-button num-pad-eight " + this.getNumPressedClass(8)} onPointerDown={(e) => { e.preventDefault(); this.onNumPressed(8) }}>8</div>
            <div className={"num-pad-button num-pad-nine " + this.getNumPressedClass(9)} onPointerDown={(e) => { e.preventDefault(); this.onNumPressed(9) }}>9</div>
            {deleteButton}
            {toggleModeButton}
            <div className={"num-pad-undo-redo"}>
            {undoButton}
            {redoButton}
            </div>

        </div>;
    }

}

export default NumPad