// @flow

import * as React from 'react';
//$FlowIgnore
import {ReactComponent as UserIconSVG} from '../usericon_color.svg';

const COLORS = ["green", "lavender", "sky-blue", "brown", "tan", "pink", "purple", "sea-blue", "red", "yellow"];

type Props = {
    children?: React.Node,
    userID: ?string,
    users: ?Array<any>,
    dancing: bool,
    // propOne: bool,
}

type State = {
    // stateOne: bool,
}

type UserIconProps = {
    children?: React.Node,
    userID: string,
    facingLeft?: bool,
    dancing: bool,
}

class UserIcon extends React.Component<UserIconProps> {
    getIconColor(userID: string): string {
        const color_idx = userID.charCodeAt(0) % COLORS.length;
        return COLORS[color_idx]
    }

    render(){
        const facingClass = this.props.facingLeft ? "userIconFaceLeft" : "userIconFaceRight"
        const colorClass = "user-icon-" + this.getIconColor(this.props.userID);
        const dancingClass = this.props.dancing ? "userIconDancing" : "";
        return <UserIconSVG className={"userIcon " + colorClass + " " + dancingClass + " " + facingClass}/>
    }
}

class UsersBar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        // initialize state:
        this.state = {};
        // do something
    }

    componentDidMount() {
        // do something 
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // do something
    }

    componentWillUnmount() {
        // do something
    }

    render(): React$Element<"div"> {
        let userIcons = [];

        if (this.props.userID != null && this.props.users != null) {
            userIcons.push(<UserIcon key={this.props.userID} userID={this.props.userID} dancing={this.props.dancing}/>);
            const users = this.props.users != null ? this.props.users.filter((user)=>{return user.user_id !== this.props.userID}) : [];
            users.forEach((user, idx) => {
                const userID = user.user_id;
                userIcons.push(<UserIcon key={userID} userID={userID} facingLeft={idx % 2 === 0 || idx === users.length - 1} dancing={this.props.dancing}/>);
            });
        }
        return <div className={"userBar"}>{userIcons}</div>;

    }
}

export default UsersBar