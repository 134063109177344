// @flow

import * as React from 'react';

type Props = {
    children?: React.Node,
    mode: string,
    multiValue: Array<number>,
    onClick: (Array<number>) => void,
    outlineColor: ?string,
    opacityClass: string,
    row: number,
    col: number,
    mutable: bool,
    selected: bool,
    // propOne: bool,
}

type State = {
    // stateOne: bool,
}

const VALUES = [1,2,3,4,5,6,7,8,9];

class Square extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        // initialize state:
        this.state = {};
        // do something
    }

    componentDidMount() {
        // do something 
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // do something
    }

    componentWillUnmount() {
        // do something
    }

    getMultiValueCell(): React$Element<"div"> {
        var children = [];
        VALUES.forEach((num: number) => {
            if (this.props.multiValue.includes(num)) {
                const val = <div key={num} className={"multiCellVal"}>{num}</div>
                children.push(val);
            } else {
                const val = <div key={num} className={"multiCellVal"}>{" "}</div>
                children.push(val);
            }
        });
        return <div className={"multiCell"}>{children}</div>
    }

    render(): React$Element<"td"> {
        const boldChildren = <b>{this.props.children}</b>
        const singleValueChildren = this.props.mutable ? this.props.children : boldChildren;
        const multiValueChildren = this.props.mode === 'multi' ? this.getMultiValueCell() : null;
        return <td
            className={"cellTableData " + (this.props.mode === "multi" ? "cellMultiValue" : "")}
            onPointerDown={(e) => { e.preventDefault(); this.props.onClick([this.props.row, this.props.col]) }}
        >
            <div className={
                "cellBackground " +
                (this.props.opacityClass) + " " +
                (this.props.outlineColor != null ? "background-" + this.props.outlineColor : "")
            } />
            <div className={"cellText"}>
                {this.props.mode === 'single' ? singleValueChildren : multiValueChildren}
            </div>
        </td>
    }

}

export default Square