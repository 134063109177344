// @flow

import React from 'react';
import Board from './components/board';
import io from 'socket.io-client';
import TopBar from './components/top_bar';
import UsersBar from './components/users_bar';
import Sidebar from "react-sidebar";

type State = {
  boardState: any,
  currentCell: ?Array<number>,
  userList: any,
  userID: string,
  session_id: string,
  socket: any,
  currentTime: number,
  intervalID: ?IntervalID,
  isSidebarOpen: bool,
  undoList: Array<any>,
  redoList: Array<any>,
  undoIdx: number,
};

const containsAll = (arr1: Array<number>, arr2: Array<number>) => 
                arr2.every(arr2Item => arr1.includes(arr2Item));

const sameMembers = (arr1: Array<number>, arr2: Array<number>) => 
                containsAll(arr1, arr2) && containsAll(arr2, arr1);

class App extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    const session_id = urlParams.get("session_id")
    this.state = { boardState: null, currentCell: null, userList: null, userID: "", session_id: session_id ?? "", socket: null, currentTime: 0, intervalID: null , isSidebarOpen: false, undoList: [], redoList: [], undoIdx: 0};
  }

  updateSquareLocally(coords: Array<number>, num: number) {
    this.setState((prevState: State) => {
      var prevBoardState = prevState.boardState;
      const cell = prevBoardState.rows[coords[0]].cells[coords[1]];
      if (cell.mutable) {
        if (prevBoardState.rows[coords[0]].cells[coords[1]].single_value === num) {
          return;
        }
        prevBoardState.rows[coords[0]].cells[coords[1]].single_value = num
      }
      return { boardState: prevBoardState };
    });
  }

  updateCellState(coords: Array<number>, cell: any) {
    this.setState((prevState: State) => {
      var prevBoardState = prevState.boardState;
      if (cell.mutable) {
        const prevCell = prevBoardState.rows[coords[0]].cells[coords[1]];
        if (prevCell.single_value === cell.single_value && sameMembers(prevCell.multi_value, cell.multi_value) && prevCell.mode === cell.mode) {
          return;
        }
        prevBoardState.rows[coords[0]].cells[coords[1]] = JSON.parse(JSON.stringify(cell));
        return { boardState: prevBoardState };
      }
    });
  }

  setCurrentTime() {
    this.setState({ currentTime: Math.floor(Date.now() / 1000) });
  }

  getDisplayTime(): number {
    const startTime = this.state.boardState != null ? this.state.boardState.start_time : 0;
    const endTime = this.state.boardState != null ? this.state.boardState.end_time : -1;
    if (endTime !== -1) {
      return endTime - startTime;
    } else if (startTime !== 0) {
      return this.state.currentTime - startTime;
    } else {
      return 0;
    }
  }

  isComplete(): bool {
    const endTime = this.state.boardState?.end_time;
    return !(endTime === -1 || endTime == null)
  }

  updateSquare(coords: Array<number>, num: number) {
    // var socket = io();
    const cell = this.state.boardState.rows[coords[0]].cells[coords[1]];
    if (cell.mutable) {
      var socket = this.state.socket;
      socket.emit('update_session_state', {
        session_id: this.state.session_id,
        name: 'update_cell_value',
        user_id: this.state.userID,
        cell_coords: coords,
        mode: 'single',
        value: num,
      });
    }
    this.updateSquareLocally(coords, num);
  }

  updateCurrentCellValue(num: number) {
    if (this.state.currentCell != null) {
      this.updateSquare(this.state.currentCell, num);
    }
  }

  updateSquareMultiValueLocally(coords: Array<number>, nums: Array<number>) {
    // var socket = io();
    this.setState((prevState: State) => {
      var prevBoardState = prevState.boardState;
      const cell = prevBoardState.rows[coords[0]].cells[coords[1]];
      if (cell.mutable) {
        const prevMultiValue = prevBoardState.rows[coords[0]].cells[coords[1]].multi_value;
        if (sameMembers(prevMultiValue, nums)) {
          return;
        }
        prevBoardState.rows[coords[0]].cells[coords[1]].multi_value = nums
      }
      return { boardState: prevBoardState };
    });
  }

  addMultiValueToCell(coords: Array<number>, num: number) {
    const boardState = this.state.boardState;
    const cell = boardState.rows[coords[0]].cells[coords[1]];
    const multiValue = cell.multi_value;
    if (multiValue.includes(num)) {
      return;
    }
    const newMultiValue = multiValue.concat(num);
    this.updateSquareMultiValueLocally(coords, newMultiValue);
  }

  removeMultiValueFromCell(coords: Array<number>, num: number) {
    const boardState = this.state.boardState;
    const cell = boardState.rows[coords[0]].cells[coords[1]];
    const multiValue = cell.multi_value;
    const newMultiValue = multiValue.filter((oldNum) => {return oldNum !== num});
    if (multiValue.length === newMultiValue.length) {
      return;
    }
    this.updateSquareMultiValueLocally(coords, newMultiValue);
  }

  updateCurrentSquareMultiValue(num: ?number) {
    // var socket = io();
    const currentCellCoords = this.state.currentCell;
    if (currentCellCoords != null) {
      const cell = this.state.boardState.rows[currentCellCoords[0]].cells[currentCellCoords[1]];
      const multiValue = cell.multi_value;
      let newMultiValue = [];
      let operation = "add";
      if (num == null) {
        operation = "clear";
      } else if (multiValue.includes(num)) {
        newMultiValue = multiValue.filter((multiNum) => {return multiNum !== num});
        operation = "remove";
      } else {
        newMultiValue = multiValue.concat(num)
      }
      if (cell.mutable) {
        this.updateSquareMultiValueLocally(currentCellCoords, newMultiValue);
        var socket = this.state.socket;
        socket.emit('update_session_state', {
          session_id: this.state.session_id,
          name: 'update_cell_value',
          user_id: this.state.userID,
          cell_coords: currentCellCoords,
          mode: 'multi',
          operation,
          value: num,
        });
      }
    }
  }



  setSquareModeLocally(coords: Array<number>, mode: string): void {
    this.setState((prevState: State) => {
      var prevBoardState = prevState.boardState;
      const cell = prevBoardState.rows[coords[0]].cells[coords[1]];
      if (cell.mutable) {
        if (prevBoardState.rows[coords[0]].cells[coords[1]].mode === mode) {
          return;
        }
        prevBoardState.rows[coords[0]].cells[coords[1]].mode = mode;
      }
      return { boardState: prevBoardState };
    });
  }

  toggleCurrentSquareMode(): void {
    this.setState((prevState: State) => {
      const coords = prevState.currentCell;
      if (coords != null) {
        var prevBoardState = prevState.boardState;
        const cell = prevBoardState.rows[coords[0]].cells[coords[1]];
        if (cell.mutable) {
          const prevMode = cell.mode;
          const newMode = prevMode === 'single' ? "multi" : 'single';
          prevBoardState.rows[coords[0]].cells[coords[1]].mode = newMode;
          var socket = this.state.socket;
          socket.emit('update_session_state', {
            session_id: prevState.session_id,
            user_id: prevState.userID,
            name: 'update_cell_mode',
            cell_coords: coords,
            value: newMode,
          });
        }
        return { boardState: prevBoardState};
      }
    });
  }

  updateCurrentCell(coords: Array<number>) {
    // var socket = io();
    var socket = this.state.socket;
    socket.emit('update_user_location', {
      session_id: this.state.session_id,
      user_id: this.state.userID,
      location: coords,
    });
    this.setState({ currentCell: coords });
  }

  componentDidMount() {
    const intervalID = setInterval(this.setCurrentTime.bind(this), 1000);
    this.setState({ intervalID: intervalID });

    var socket = io();
    this.setState({ socket: socket });

    socket.on('get_session', (msg) => {
      // console.log('game state: ' + msg);
      const parsed = JSON.parse(msg);
      console.log(parsed);
      this.setState({ boardState: parsed });
    });

    socket.on('session_does_not_exist', (msg) => {
      console.log('session does not exist');
    });

    socket.on('puzzle_complete', (msg) => {
      console.log('puzzle_complete');
      console.log(msg);
      this.setState((prevState: State) => {
        var prevBoardState = prevState.boardState;
        prevBoardState.end_time = msg.data;
        return { boardState: prevBoardState };
      });
    });

    socket.on('update_session_state', (update) => {
      const msg = update.data;
      console.log(msg);
      if (msg.name === 'update_cell_value') {
        if (msg.mode === 'single') {
          this.updateSquareLocally(msg.cell_coords, msg.value);
        } else {
          if (msg.operation === "add") {
            this.addMultiValueToCell(msg.cell_coords, msg.value);
          } else if (msg.operation === "remove") {
            this.removeMultiValueFromCell(msg.cell_coords, msg.value);
          } else { // clear
            this.updateSquareMultiValueLocally(msg.cell_coords, []);
          }
        }
      } else if (msg.name === 'update_cell_mode') {
        this.setSquareModeLocally(msg.cell_coords, msg.value);
      } else if (msg.name === 'update_cell_state') {
        this.updateCellState(msg.cell_coords, msg.cell);
      }
      if (msg.user_id !== this.state.userID) {
        this.filterUndoAndRedoLists(msg.cell_coords);
      }
      // const parsed = JSON.parse(msg);
    });

    socket.on('update_user_list', (update) => {
      const msg = update.data;
      console.log(msg);
      this.setState({ userList: msg });
      // console.log(msg);
      // if (msg.name === 'update_cell') {
      //   this.updateSquareLocally(msg.cell_coords, msg.value);
      // }
      // // const parsed = JSON.parse(msg);
    });

    socket.on('connect', () => {
      socket.emit('add_user', {
        session_id: this.state.session_id,
        user_id: socket.id.slice(0, 8),
      });
      this.setState({ userID: socket.id.slice(0, 8) });
  
      socket.emit('get_session', {
        session_id: this.state.session_id,
      });
    });

  }

  componentWillUnmount() {
    if (this.state.intervalID != null) {
      clearInterval(this.state.intervalID);
    }
  }

  setSidebarOpen(state: bool) {
    console.log("setting sidebar open state");
    console.log(state);
    this.setState({ isSidebarOpen: state });
  }

  addCurrentCellStateToUndoListAndClearUndoIdx() {
    this.setState((prevState: State) => {
      const currentCellCoords = prevState.currentCell;
      if (currentCellCoords != null) {
        const currentCell = prevState.boardState?.rows[currentCellCoords[0]].cells[currentCellCoords[1]];
        // if currentCell.mutable
        const slicedUndoList = prevState.undoList.slice(0, prevState.undoList.length-prevState.undoIdx);
        const newRedoList = prevState.redoList.slice(0, prevState.redoList.length-prevState.undoIdx);
        const newUndoList = slicedUndoList.concat(JSON.parse(JSON.stringify({coords: currentCellCoords, cell: currentCell})));
        return {undoList: newUndoList, redoList: newRedoList, undoIdx: 0};
      }
    });
  }

  addCurrentCellStateToRedoList() {
    this.setState((prevState: State) => {
      const currentCellCoords = prevState.currentCell;
      if (currentCellCoords != null) {
        const currentCell = prevState.boardState?.rows[currentCellCoords[0]].cells[currentCellCoords[1]];
        const newRedoList = prevState.redoList.concat(JSON.parse(JSON.stringify({coords: currentCellCoords, cell: currentCell})));
        return {redoList: newRedoList};
      }
    });
  }

  undo() {
    this.setState((prevState: State)=>{
      let prevUndoList = prevState.undoList;
      console.log(prevUndoList);
      const undoItem = prevUndoList[prevUndoList.length - 1 - prevState.undoIdx];
      if (undoItem != null) {
        const undoCoords = undoItem.coords;
        const undoCell = undoItem.cell;
        let prevBoardState = prevState.boardState;
        prevBoardState.rows[undoCoords[0]].cells[undoCoords[1]] = JSON.parse(JSON.stringify(undoCell));

        let socket = this.state.socket;
        socket.emit('update_session_state', {
          session_id: this.state.session_id,
          name: 'update_cell_state',
          user_id: this.state.userID,
          cell_coords: undoCoords,
          cell: undoCell,
        });
        return {undoList: prevUndoList, boardState: prevBoardState, undoIdx: prevState.undoIdx + 1}
      }

    });
  }

  redo() {
    this.setState((prevState: State)=>{
      let prevRedoList = prevState.redoList;
      const redoItem = prevRedoList[prevRedoList.length - prevState.undoIdx];
      console.log(redoItem);
      if (redoItem != null) {
        const redoCoords = redoItem.coords;
        const redoCell = redoItem.cell;
        let prevBoardState = prevState.boardState;
        // const undoCell = JSON.parse(JSON.stringify({coords: redoCoords, cell: prevBoardState.rows[redoCoords[0]].cells[redoCoords[1]]}));
        // let undoList = prevState.undoList;
        // undoList.push(undoCell);
        prevBoardState.rows[redoCoords[0]].cells[redoCoords[1]] = JSON.parse(JSON.stringify(redoCell));
        // redoList.pop();

        let socket = this.state.socket;
        socket.emit('update_session_state', {
          session_id: this.state.session_id,
          name: 'update_cell_state',
          user_id: this.state.userID,
          cell_coords: redoCoords,
          cell: redoCell,
        });
        return {boardState: prevBoardState, undoIdx: prevState.undoIdx - 1}
      }

    });
  }

  filterUndoAndRedoLists(coords: Array<number>) {
    this.filterUndoList(coords);
    this.filterRedoList(coords);
  }

  filterRedoList(coords: Array<number>) {
    this.setState((prevState: State)=>{
      let prevRedoList = prevState.redoList;
      const newRedoList = prevRedoList.filter((redoItem) => {return !(redoItem.coords[0] === coords[0] && redoItem.coords[1] === coords[1])});
      return {redoList: newRedoList, boardState: prevState.boardState};
    });
  }

  filterUndoList(coords: Array<number>) {
    this.setState((prevState: State)=>{
      let prevUndoList = prevState.undoList;
      let newUndoIndex = prevState.undoIdx;
      const newUndoList = prevUndoList.filter((undoItem, idx) => {
        const shouldRemove = (undoItem.coords[0] === coords[0] && undoItem.coords[1] === coords[1]);
        if (shouldRemove && idx >= prevState.undoList.length - newUndoIndex) {
          newUndoIndex -= 1;
        }
        return !(undoItem.coords[0] === coords[0] && undoItem.coords[1] === coords[1])
      });
      return {undoIdx: newUndoIndex, undoList: newUndoList, boardState: prevState.boardState};
    });
  }

  render(): React$Element<"div"> {
    const spinner = <div className="lds-ring" style={{display: "block", marginTop: "100px"}}><div></div><div></div><div></div><div></div></div>;
    return (
      <div className="App">
        {/* <Sidebar
          sidebarClassName={"sidebar"}
          pullRight={true}
          sidebar={<b>Sidebar content</b>}
          open={this.state.isSidebarOpen}
          onSetOpen={this.setSidebarOpen.bind(this)}
          
          styles={{ sidebar: { background: "white" } }}
        > */}
          <TopBar time={this.getDisplayTime()} complete={this.isComplete()} setSidebarOpen={this.setSidebarOpen.bind(this)}/>
          <UsersBar users={this.state.userList} userID={this.state.userID} dancing={this.isComplete()} />
          {this.state.boardState !== null ? <Board currentCell={this.state.currentCell} onUpdateCurrentCell={this.updateCurrentCell.bind(this)} onToggleCurrentCellMode={this.toggleCurrentSquareMode.bind(this)} onUpdateCurrentCellValue={this.updateCurrentCellValue.bind(this)} onUpdateCurrentCellMultiValue={this.updateCurrentSquareMultiValue.bind(this)} addCurrentCellStateToRedoList={this.addCurrentCellStateToRedoList.bind(this)} addCurrentCellStateToUndoList={this.addCurrentCellStateToUndoListAndClearUndoIdx.bind(this)} onUndo={this.undo.bind(this)} onRedo={this.redo.bind(this)} boardState={this.state.boardState} userList={this.state.userList} userID={this.state.userID} /> : spinner}
        {/* </Sidebar> */}
      </div>
    );
  }
}

export default App;
