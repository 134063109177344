// @flow

import * as React from 'react';

type Props = {
    children?: React.Node,
    puzzleString?: string,
    puzzleID?: string,
    onClick: () => void,
    // propOne: bool,
}

type State = {
    // stateOne: bool,
    puzzleString: ?string,
}

class MiniBoard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        // initialize state:
        this.state = {puzzleString: null};
        // do something
    }

    componentDidMount() {
        if (this.props.puzzleID != null) {
            const fetchURL = '/puzzle/id/' + this.props.puzzleID;
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };
        fetch(fetchURL, requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({puzzleString: data.puzzle_string})
            }
            );
        } else {
            this.setState({puzzleString: this.props.puzzleString})
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // do something
    }

    componentWillUnmount() {
        // do something
    }

    render(): React$Element<"div"> {
        const rowIdxs = [0, 1, 2, 3, 4, 5, 6, 7, 8];
        const colIdxs = [0, 1, 2, 3, 4, 5, 6, 7, 8];
        const table = this.state.puzzleString != null ? <table className="minisudoku" onClick={(e) => {e.preventDefault(); this.props.onClick()}}>
            <tbody className="minisudoku">
                {rowIdxs.map(rowIdx => {
                    return (
                        <tr key={rowIdx} className="minisudoku">
                            {colIdxs.map(colIdx => {
                                const char = this.state.puzzleString != null ? this.state.puzzleString.charAt((rowIdx * 9) + colIdx) : "0";
                                return <td key={colIdx} className="minisudoku">
                                    {char !== "0" ? char : " "}
                                </td>;
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table> : <div />;
        return <div>
            {table}
        </div>;
    }

}

export default MiniBoard