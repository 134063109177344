// @flow

import * as React from 'react';
import MiniBoard from './miniboard';
import Collapsible from 'react-collapsible';
import logoIcon from '../logocolor_roundcorners.svg';
import "./top_bar.css";
import "./home.css";
import "../spinner.css"

type Props = {
    children?: React.Node,
    // propOne: bool,
}

type State = {
    // stateOne: bool,
    easyPuzzles: Array<any>,
    mediumPuzzles: Array<any>,
    hardPuzzles: Array<any>,
    veryHardPuzzles: Array<any>,
    showAboutPage: bool,
}

class Home extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        // initialize state:
        this.state = { easyPuzzles: [], mediumPuzzles: [], hardPuzzles: [], veryHardPuzzles: [], showAboutPage: false };
        // do something
    }

    componentDidMount() {
        this.getRandomPuzzles("EASY");
        this.getRandomPuzzles("MEDIUM");
        this.getRandomPuzzles("HARD");
        this.getRandomPuzzles("VERYHARD");
    }

    getRandomPuzzles(difficulty: string) {
        const fetchURL = '/puzzle/' + difficulty;
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(fetchURL, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                this.setPuzzles(difficulty, data.puzzle_ids);
            }
            );
    }

    setPuzzles(difficulty: string, puzzleIDs: Array<string>) {
        switch (difficulty) {
            case "EASY":
                this.setState({ easyPuzzles: puzzleIDs });
                break;
            case "MEDIUM":
                this.setState({ mediumPuzzles: puzzleIDs });
                break
            case "HARD":
                this.setState({ hardPuzzles: puzzleIDs });
                break
            case "VERYHARD":
                this.setState({ veryHardPuzzles: puzzleIDs });
                break
            default:
                break;
        }
    }

    getSpinner(): React$Element<"div"> {
        return <div className="lds-ring"><div></div><div></div><div></div><div></div></div>;
    }

    getMiniboards(puzzleIDs: Array<string>): React$Element<"div"> {
        if (puzzleIDs.length === 0) {
            return <div className={"miniboardsContainer"}>{this.getSpinner()}</div>;
        }
        const miniPuzzles = puzzleIDs.map((puzzleID, idx) => {
            return <div className={"miniboard"} key={idx}>
                <MiniBoard puzzleID={puzzleID} onClick={() => { this.createPuzzleAndRedirect(puzzleID) }} />
            </div>
        })
        return <div className={"miniboardsContainer"}>{miniPuzzles}</div>;
    }

    createPuzzleAndRedirect(puzzleID: string) {
        const fetchURL = '/puzzle/id/' + puzzleID;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        };
        fetch(fetchURL, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                const newURL = "?page=game&session_id=" + data.session_id;
                window.location.assign(newURL);
            }
            );
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // do something
    }

    componentWillUnmount() {
        // do something
    }

    getCollapsibleTrigger(name: string, open: boolean): React$Element<"div"> {
        return <div className={"collapsibleTrigger"}>
            <span className={"triggerName"}>{name}</span>
            <span className={"triggerIcon"}>{open ? "-" : "+"}</span>
        </div>;
    }

    render(): React$Element<"div"> {
        const easyPuzzles = this.getMiniboards(this.state.easyPuzzles);
        const mediumPuzzles = this.getMiniboards(this.state.mediumPuzzles);
        const hardPuzzles = this.getMiniboards(this.state.hardPuzzles);
        const veryHardPuzzles = this.getMiniboards(this.state.veryHardPuzzles);
        const puzzleContent = <>
            <div className={"introText"}> Touch a puzzle below to start a new session. Send the link to a friend to play together!</div>
            <div className={"collapsibles"}>
                <div className={"collapsibleDivisor"} />
                <Collapsible open={true} containerElementProps={{ className: "collapsibleContent" }} trigger={this.getCollapsibleTrigger("Easy", false)} triggerWhenOpen={this.getCollapsibleTrigger("Easy", true)} transitionTime={200}>
                    {easyPuzzles}
                </Collapsible>
                <div className={"collapsibleDivisor"} />
                <Collapsible containerElementProps={{ className: "collapsibleContent" }} trigger={this.getCollapsibleTrigger("Medium", false)} triggerWhenOpen={this.getCollapsibleTrigger("Medium", true)} transitionTime={200}>
                    {mediumPuzzles}
                </Collapsible>
                <div className={"collapsibleDivisor"} />
                <Collapsible containerElementProps={{ className: "collapsibleContent" }} trigger={this.getCollapsibleTrigger("Hard", false)} triggerWhenOpen={this.getCollapsibleTrigger("Hard", true)} transitionTime={200}>
                    {hardPuzzles}
                </Collapsible>
                <div className={"collapsibleDivisor"} />
                <Collapsible containerElementProps={{ className: "collapsibleContent" }} trigger={this.getCollapsibleTrigger("Very Hard", false)} triggerWhenOpen={this.getCollapsibleTrigger("Very Hard", true)} transitionTime={200}>
                    {veryHardPuzzles}
                </Collapsible>
                <div className={"collapsibleDivisor"} />
            </div>
        </>;

        const about =
        <>
            <div className={"aboutTitle"}>About:</div>
            <div className={"aboutText"}> This site allows you to play sudoku in real time with your friends. 
            Send them a link to your session to play together. All people viewing the same puzzle page will be in sync. 
            I hope you have fun playing with your friends!
                <br/><br/>
            This site was made by <a href={"https://lisandroj.org"}>Lisandro Jimenez Leon</a> using React and Python. If you want to get in touch, feel free to reach out on <a href={"https://www.linkedin.com/in/lisandro-jimenez-leon-134a9134/"}>my LinkedIn page</a>
            </div>
        </>
        return <div className={"homePage"}>
            <div className="home-topbar-grid-container">
                <div className="home-topbar-logo"><a href="."><img src={logoIcon} height={'40px'} /></a></div>
                <div className="home-topbar-logo-text"><a href="." className={"home-topbar-logo-text"}>sudoku<span className={"logo-with"}>with.</span>us</a></div>
            </div>
            {puzzleContent}
            {about}

        </div>
    }

}

export default Home